import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-r from-purple-600 to-blue-600 text-white shadow-lg">
      <div className="container mx-auto py-2 px-4 flex justify-between items-center">
        <nav className="hidden md:flex-1 md:flex md:justify-start">
          <ul className="flex space-x-6">
            <li><a href="#services" className="hover:text-purple-200 transition-colors">Services</a></li>
            <li><a href="#about" className="hover:text-purple-200 transition-colors">About</a></li>
            <li><a href="#contact" className="hover:text-purple-200 transition-colors">Contact</a></li>
          </ul>
        </nav>
        <div className="flex-1 flex justify-center">
          <img src="/images/og-image.png" alt="Millennium Marble & Granite Logo" className="h-12" />
        </div>
        <div className="flex-1 flex justify-end">
          <button 
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <nav className="md:hidden bg-purple-700 py-4">
          <ul className="flex flex-col items-center space-y-4">
            <li><a href="#services" className="hover:text-purple-200 transition-colors">Services</a></li>
            <li><a href="#about" className="hover:text-purple-200 transition-colors">About</a></li>
            <li><a href="#contact" className="hover:text-purple-200 transition-colors">Contact</a></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
