import React from 'react';

 const About = () => {
   return (
     <section id="about" className="py-16 bg-gradient-to-r from-purple-100 to-blue-100">
       <div className="container mx-auto px-4">
         <h2 className="text-4xl font-bold text-center mb-12">Our Story</h2>
         <div className="flex flex-col md:flex-row items-center md:items-start">
           <div className="md:w-1/3 mb-8 md:mb-0 flex flex-col items-center">
             <div className="relative w-64 h-64 mb-4">
               <div className="absolute inset-0 bg-gradient-to-r from-purple-400 to-blue-500 rounded-full transform rotate-3 animate-pulse"></div>
               <div className="absolute inset-0 overflow-hidden rounded-full transform -rotate-3 transition-transform duration-300 hover:rotate-0">
                 <img
                   src="/images/IMG_9938.png"
                   alt="Jay Keefe, CEO"
                   className="w-full h-full object-cover object-center"
                 />
               </div>
               <div className="absolute inset-0 border-4 border-white rounded-full pointer-events-none"></div>
             </div>
             <p className="text-center font-semibold text-xl">Jay Keefe</p>
             <p className="text-center text-gray-600">Founder & CEO</p>
           </div>
           <div className="md:w-2/3 md:pl-12">
             <h3 className="text-2xl font-semibold mb-4">Crafting Excellence in Stone</h3>
             <p className="text-lg mb-4">
               Since 2001, Millennium Marble & Granite has been at the forefront of the stone industry in Wrentham, MA. What began as a small family business has grown into a premier provider of exquisite stone
 solutions, thanks to our unwavering commitment to quality and customer satisfaction.
             </p>
             <p className="text-lg mb-4">
               Our journey is marked by continuous innovation and a deep respect for the timeless beauty of natural stone. We've invested in state-of-the-art technology and cultivated a team of skilled artisans
 who share our passion for perfection.
             </p>
             <p className="text-lg mb-4">
               At Millennium Marble & Granite, we don't just sell stone; we create living art. Each project is a unique opportunity to bring our clients' visions to life, whether it's a stunning kitchen
 countertop, an elegant bathroom vanity, or a statement piece for a commercial space.
             </p>
             <p className="text-lg">
               As we look to the future, our mission remains clear: to continue setting the standard for excellence in the stone industry, one project at a time. We invite you to experience the Millennium
 difference and let us help you transform your space into something truly extraordinary.
             </p>
           </div>
         </div>
       </div>
     </section>
   );
 };

 export default About;
