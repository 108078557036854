import React from 'react';

const Hero = () => {
  return (
    <section className="relative h-screen flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0">
        <img
          src="/images/stock.avif"
          alt="Marble background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-30"></div>
      </div>
      <div className="relative z-10 text-center text-white px-4">
        <h2 className="text-5xl md:text-7xl font-bold mb-4 animate-fade-in-down">
          Millennium Marble &amp; Granite
        </h2>
        <p className="text-xl md:text-2xl mb-8 animate-fade-in-up">
          Transform your space with our premium stone solutions
        </p>
        <a
          href="#contact"
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-full transition-colors animate-pulse"
        >
          Get a Quote
        </a>
      </div>
    </section>
  );
};

export default Hero;
