import React from 'react';

const ServiceCard = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
    <div className="text-4xl text-purple-600 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Services = () => {
  const services = [
    {
      title: "Custom Fabrication",
      description: "Tailored marble and granite countertops to fit your unique space.",
      icon: "🔨",
    },
    {
      title: "Installation",
      description: "Professional installation for kitchens, bathrooms, and more.",
      icon: "🏠",
    },
    {
      title: "Wide Selection",
      description: "Choose from a variety of colors, patterns, and finishes.",
      icon: "🎨",
    },
  ];

  return (
    <section id="services" className="py-16">
      <h2 className="text-4xl font-bold text-center mb-12">Our Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
    </section>
  );
};

export default Services;
