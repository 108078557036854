import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-purple-100 flex flex-col">
      <Header />
      <div className="pt-16"> {/* Add padding-top to account for fixed header */}
        <Hero />
        <main className="container mx-auto flex-grow py-12 px-4">
          <Services />
          <About />
          <Contact />
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default App;
