import React from 'react';

const Contact = () => {
  return (
    <section id="contact" className="py-16 bg-gradient-to-r from-blue-100 to-purple-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Contact Us</h2>
        <div className="text-center">
          <h3 className="text-2xl font-semibold mb-4">Get in Touch</h3>
          <p className="mb-4">
            <strong>Address:</strong> 35 Commercial Dr, Wrentham, MA 02093
          </p>
          <p className="mb-4">
            <strong>Phone:</strong>{' '}
            <a href="tel:(508)647-8800" className="text-blue-600 hover:text-blue-800">
              (508) 647-8800
            </a>
          </p>
          <p className="mb-4">
            <strong>Email:</strong>{' '}
            <a 
              href="mailto:info@millenniumdesigngroup.com?cc=millennium.marble@yahoo.com" 
              className="text-blue-600 hover:text-blue-800"
            >
              info@millenniumdesigngroup.com
            </a>
          </p>
          <p className="mb-4">
            <strong>Hours:</strong>
            <br />
            Monday to Friday: 8:30 AM - 5:00 PM
            <br />
            Weekends: By appointment only
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
